<template>

  <b-card>
    <b-card-header class="pb-50">
      <h5>Filtros</h5>
    </b-card-header>
    <b-card-body>
        <b-row>
            <b-col
                    cols="12"
                    md="4"
                    class="mb-md-0 mb-2"
            >
                <label>Estado</label>
                <v-select
                        v-model="estadoFilter"
                        label="text"
                        :options="estadoOptions"
                        class="w-100"
                        :reduce="option => option.value"
                        :searchable="false"
                        @input="updateFilters"
                />

            </b-col>
        </b-row>
    </b-card-body>
  </b-card>

</template>

<script>

import {BCard, BCardHeader, BCardBody, BCol, BRow} from 'bootstrap-vue'
import vSelect from "vue-select";
import { fetchFormStatusOptions } from "@/modules/common/utils";
import {mapMutations} from "vuex";
export default {
    name: 'SolicitudesCreacionFilters',
    components: {
        BRow, vSelect, BCol,
        BCard,
        BCardHeader,
        BCardBody
    },
    data() {
        return {
            estadoFilter: null,
            estadoOptions: fetchFormStatusOptions(),

        }
    },
    methods: {

        ...mapMutations('solicitante-solicitudes-module', ['setFiltersCriteria']),

        updateFilters(){

            const filtersCriteria = []

            if ( this.estadoFilter != null )
                filtersCriteria.push( { field: 'fstStatus', values: [ this.estadoFilter ] } )

            this.setFiltersCriteria( filtersCriteria )

        }
    },
}
</script>

<style scoped>

</style>
